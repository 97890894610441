<template>
    <div class="container">
         <div class="big_con">
            <div class="small_con">
                <div class="title">
                   <h1 class="titles">{{details.title}}</h1>
                    <p class="date_time">{{details.create_time}}</p>
                    <div class="img_box">
                        <img :src="details.img | ImgFilter" />
                    </div>
                    <div class="text_wen" v-html="details.content">

                    </div>
                </div>
            </div>
         </div>
    </div>
</template>

<script>
import { dynamic_xq } from "../../common/js/api";
    export default {
        name: "dongtai",
        data(){
            return{
                details:{}
            }
        },
        created(){
            this.applys()
        },
        filters: {
            ImgFilter(value) {
                return "http://hout.kehui.cloud" + value;
            },
        },
        methods:{
            applys(){
                dynamic_xq({
                    id:this.$route.query.id
                }) .then((res) => {
                   console.log(res.data)
                    this.details=res.data
                }).catch((err) => {
                });
            },
        }
    }
</script>

<style scoped lang="less">
    .container {
        background-color: #f8f9fa;
        background-image: url("~@/assets/img/home/bg_ren.png");
        background-size: cover;
        width: 100%;
        height: auto;
        overflow: hidden;
		margin-top: 135px;
        .big_con{
            width: 1200px;
            background-color: #fff;
            margin: 0 auto;
            padding-bottom: 20px;
            .small_con{
                width: 80%;
                margin: 0 auto;

                .title{
                    text-align: center;

                    .titles{
                        padding-top: 20px;
                        overflow: hidden;
                        margin: 20px;
                        color: rgba(16, 16, 16, 100);
                        font-size: 28px;
                    }
                    .date_time{
                        margin-bottom: 20px;
                        color: rgba(105, 105, 105, 100);
                        font-size: 12px;
                    }
                    .img_box{
                        margin: 20px auto;
                        width: 612px;
                        height: 288px;
                        img{
                            width: 100%;

                        }
                    }

                    .text_wen{
                        margin: 0 auto;
                        width: 625px;
                        color: rgba(105, 105, 105, 100);
                        font-size: 16px;
                        text-align: left;
                        line-height: 25px;
                    }
                }
            }
        }
    }
	@media screen and (max-width: 1200px) {
		 .container{
			 margin-top: 15rem;
		 }
		 .container .big_con{
			 width: 100%;
			 margin: 0;
		 }
		 .container .big_con .small_con{
			 width: 96%;
		 }
		 .container .big_con .small_con .title .img_box{
			 width: 100%;
			 height: auto;
		 }
		 .container .big_con .small_con .title .text_wen{
			 width: 100%;
			 font-size: 1.4rem;
			 line-height: 2rem;
		 }
	}
</style>